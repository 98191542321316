import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import T1 from './tdlr-accuracy/t1.mdx';
import T2 from './tdlr-accuracy/t2.mdx';
import T3 from './tdlr-accuracy/t3.mdx';
import T4 from './tdlr-accuracy/t4.mdx';
import T5 from './tdlr-accuracy/t5.mdx';
import T6 from './tdlr-accuracy/t6.mdx';
import T7 from './tdlr-accuracy/t7.mdx';
import T8 from './tdlr-accuracy/t8.mdx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "i-tdlr-statements-and-determination-of-their-accuracy"
    }}>{`I. TDLR Statements and Determination of their Accuracy`}</h1>
    <br />
    <T1 mdxType="T1" />
    <hr></hr>
    <br />
    <T2 mdxType="T2" />
    <hr></hr>
    <br />
    <T3 mdxType="T3" />
    <hr></hr>
    <br />
    <T4 mdxType="T4" />
    <hr></hr>
    <br />
    <T5 mdxType="T5" />
    <hr></hr>
    <br />
    <T6 mdxType="T6" />
    <hr></hr>
    <T7 mdxType="T7" />
    <hr></hr>
    <T8 mdxType="T8" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      